<!--合同管理-->
<template>
  <div class="panel userList">
    <div class="serch_box">
      <el-form
        :inline="true"
        class="user-search fixed_form"
        width="210px"
      >
        <el-form-item label="Contract No:">
          <template>
            <el-input
              v-model.trim="form.contractNo"
              placeholder="please enter Contract No"
              size="mini"
              maxlength="100"
            />
          </template>
        </el-form-item>
        <el-form-item label="Enterprise Number:" width="210px">
          <template>
            <el-input
              v-model.trim.number="form.enterpriseId"
              oninput="value=value.replace(/[^0-9.]/g,'')"
              size="mini"
              placeholder="please enter Enterprise Number"
              maxlength="9"
            />
          </template>
        </el-form-item>
        <el-form-item label="Contract Type:" size="mini">
          <template>
            <el-select
              v-model.trim="form.contractType"
              placeholder="Please Choose"
              size="mini"
              @change="change()"
            >
              <el-option
                v-for="item in contractTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
        <el-form-item v-if="Loadshow" label="Sales ERP:" size="mini">
          <template>
            <el-input
              v-model.trim="form.saler"
              size="mini"
              placeholder="please enter Sales ERP"
              maxlength="50"
            />
          </template>
        </el-form-item>
        <el-form-item
          v-if="Loadshow"
          label="Business Audit Status:"
          size="mini"
        >
          <template>
            <el-select
              v-model.trim="form.businessAuditState"
              placeholder="Please Choose"
              size="mini"
              @change="change()"
            >
              <el-option
                v-for="item in businessAuditStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
        <el-form-item
          v-if="Loadshow"
          label="CreditControl Audit Status:"
          size="mini"
        >
          <template>
            <el-select
              v-model.trim="form.creditAuditState"
              placeholder="Please Choose"
              size="mini"
              @change="change()"
            >
              <el-option
                v-for="item in creditControlAuditStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
        <el-form-item v-if="Loadshow" label="Validity Type:" size="mini">
          <template>
            <el-select
              v-model.trim="form.state"
              placeholder="Please Choose"
              size="mini"
              @change="change()"
            >
              <el-option
                v-for="item in validityTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
        <!-- <el-form-item v-if="Loadshow" label="Enterprise Name:" size="mini">
          <template>
            <el-input
              v-model.trim="form.enterpriseName"
              placeholder="please enter Enterprise Name"
              size="mini"
              maxlength="100"
            />
          </template>
        </el-form-item> -->
        <el-form-item size="mini">
          <template>
            <el-button type="text" size="mini" @click="loadshowBtn">
              MoreOptions
              <i
                :class="
                  Loadshow == true ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                "
                style="margin-left: 5px"
              />
            </el-button>
          </template>
        </el-form-item>
      </el-form>
      <div style="margin-left: 20px">
        <el-button type="" size="mini" @click="offBtn">Reset</el-button>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="searchBtn"
        >
          Search
        </el-button>
      </div>
    </div>
    <div class="panel-body">
      <el-table
        :data="tableList"
        fit
        style="width: 100%"
        :header-cell-style="{ background: '#e8f1fe' }"
        highlight-current-row
        @current-change="handleCurrentChanges"
      >
        <!-- 修改无数据时 提示文字 -->
        <template slot="empty" class="logo_no">
          <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
          <p style="color: #969799">No more data</p>
        </template>
        <el-table-column
          label="Contract No"
          min-width="220"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span class="font_blue">
              {{ scope.row.contractNo }}
            </span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="Enterprise Number"
          min-width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.enterpriseId || '--' }}</span>
          </template>
        </el-table-column> -->
        <el-table-column
          label="Enterprise Name"
          min-width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.enterpriseName || '--' }}</span>
          </template>
        </el-table-column>

        <!-- <el-table-column
          label="Contract Type"
          min-width="120"
          :show-overflow-tooltip="true"
        >
          <template>
            VSP
          </template>
        </el-table-column> -->
        <el-table-column
          label="Contract validity"
          min-width="150"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.contractStartTime| filterDate }} To {{ scope.row.contractEndTime| filterDate }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pageWrapper">
        <el-pagination
          :current-page="listQuery.pageCode"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="listQuery.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="listQuery.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
// 引入API接口
import { getContractList, userBind, changeswitch } from '@/api/user/list';
import dateFormate from '../../utils/date'
const defaultForm = {
  pin: '',
  contractNo: '',
  userType: ''
};
export default {
  // 引入局部刷新
  inject: ['reload'],
  filters:{
    filterDate(dateStr){
      return dateFormate(dateStr,'yyyy-MM-dd hh:mm:ss')
    }
  },
  data() {
    return {
      emptyImgSrc: '',
      postForm: Object.assign({}, defaultForm),
      // 配置分页信息
      listQuery: {
        pageSize: 10,
        pageCode: 1,
        total: 0 // 总页数
      },
      // 搜索条件
      form: {
        contractNo: '',
        enterpriseId: null,
        contractType: 0,
        saler: '',
        businessAuditState: '',
        creditAuditState: '',
        state: null,
        // enterpriseName: ''
      },
      userTypes: '',
      // 字典表
      contractTypeList: [{ value: 0, label: 'VSP' }],
      businessAuditStatusList: [
        { value: 1, label: 'Passed' },
        { value: 2, label: 'Rejected' },
        { value: 3, label: 'Waiting Audit' }
      ],
      creditControlAuditStatusList: [
        { value: 1, label: 'Passed' },
        { value: 2, label: 'Reject' },
        { value: 3, label: 'Waiting CreditVontrol Audit' }
      ],
      validityTypeList: [
        { value: 0, label: 'Valid' },
        { value: 1, label: 'invalid' }
      ],
      UserFlagList: [{ value: '1', label: 'VSP' }],
      ValidityList: [
        { value: 0, label: 'Main User' },
        { value: 1, label: 'Child User' }
      ],
      UserFlagListss: [
        { value: '1', label: 'Main User' },
        { value: '2', label: 'Child User' }
      ],
      // 后端获取表格信息
      tableList: [],
      Loadshow: false,
      textMap: {
        update: 'update',
        create: 'create'
      },
      dialogFormVisible: false,
      dialogStatus: 'update',
      rules: {
        pin: [
          {
            required: true,
            message: 'Please enter the Squence number',
            trigger: 'blur'
          }
        ],
        contractNo: [
          {
            required: true,
            message: 'Please enter the brand Name',
            trigger: 'blur'
          }
        ],
        userFlag: [
          {
            required: true,
            message: 'Please Choose User Flag',
            trigger: 'blur'
          }
        ],
        userType: [
          {
            required: true,
            message: 'Please Choose User Type',
            trigger: 'blur'
          }
        ],
        Loadright: false,
        currentRow: null
      }
    };
  },
  watch: {
    dialogFormVisible: function() {
      this.$refs['postForm'].resetFields();
    }
  },
  created() {
    // 初始化表数据
    this.getFirstData();
    this.emptyImgSrc = '';
  },
  methods: {
    resetName() {
      this.form.contractNo = '';
      this.form.enterpriseId = null;
      this.form.contractType = 0;
      this.form.saler = '';
      this.form.businessAuditState = '';
      this.form.creditAuditState = '';
      this.form.state = null;
    },
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChanges(val) {
      this.currentRow = val;
      this.$emit('handleCurrentChanges', this.currentRow);
    },
    loadshowBtn() {
      this.Loadshow = !this.Loadshow;
    },
    conBtn(row) {
      //console.log(row);
      row.flang = 3;
      this.$router.push({
        name: 'Create a contract',
        path: '/contractManage/addContract',
        query: { cont: row }
      });
    },
    changswitch(row) {
      var obj = {
        id: row.id,
        state: row.state
      };
      changeswitch(obj).then(res => {
        if (res.data.code == 200) {
          this.$message({
            message: 'Succeeded in changing the status',
            type: 'success'
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    },
    LoadrightBtn() {
      this.Loadright = !this.Loadright;
    },
    // 获取页面数据
    getFirstData() {
      // this.total = 1;
      getContractList(
        Object.assign(
          {
            pageNo: this.listQuery.pageCode,
            pageSize: this.listQuery.pageSize
          },
          this.form
        )
      ).then(res => {
        if (res.success && res.code === 200) {
          this.tableList = res.data.dataList;
          this.listQuery.total = res.data.totalCount;
        } else {
          // 暂无数据
          this.tableList = [];
          this.listQuery.total = 0;
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    // 新增
    addBtn() {
      this.$router.push({
        name: 'Create a contract',
        path: '/contractManage/addContract'
      });
    },
    // 分页信息-设置条数
    handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.getFirstData();
    },
    // 分页信息-切换页数
    handleCurrentChange(val) {
      this.listQuery.pageCode = val;
      this.getFirstData();
    },
    // 搜索栏-搜索按钮
    searchBtn() {
      this.listQuery.pageCode = 1;
      this.getFirstData();
    },
    // 搜索栏-清空按钮
    offBtn() {
      this.resetName();
    },
    viewUser(row) {
      this.$router.push({
        name: 'users list',
        path: '/user/list',
        query: { cont: row }
      });
    },
    bundleUser(row) {
      this.postForm.contractNo = row.contractNo;
      this.dialogFormVisible = true;
    },
    unbindUser(row) {
      this.$router.push({
        name: 'users list',
        path: '/user/list',
        query: { cont: row }
      });
    },
    createData() {
      this.$refs['postForm'].validate(valid => {
        if (valid) {
          userBind(this.postForm).then(res => {
            if (res.code == 200) {
              this.$message({
                message: 'successfully upload',
                type: 'success'
              });
              this.getFirstData();
              this.dialogFormVisible = false;
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              });
              
            }
          });
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    copy(contractNo) {
      this.$copyText(contractNo)
        .then(
          this.$message({
            message: 'Copy Success',
            type: 'success'
          })
        )
        .catch(err => {
          this.$message({
            message: 'Copy Failed',
            type: 'error'
          });
        });
    }
  }
};
</script>
<style lang="less" scoped>
.fixed_form {
  .el-input {
    width: 200px;
    margin-right: 30px;
  }
}
.serch_box {
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  .user-search{
    display: flex;
    flex-wrap: wrap;
    /deep/.el-form-item{
      margin-bottom: 18px;
      width: calc(50% - 10px);
      display: flex;
    }
  }
  ::v-deep .el-input__inner {
    height: 32px;
    width: 200px;
    line-height: 32px;
  }

}
.userList {
  ::v-deep .el-form-item__content {
    width: 230px;
  }
  ::v-deep .el-input__inner {
    height: 28px;
    line-height: 28px;
  }
  .el-pagination__editor.el-input {
    width: 50px;
  }
  .pageWrapper {
    margin: 10px 10px 10px 0;
    float: right;
    height: 45px;
  }
}
.logo_no {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/deep/.el-form-item__label {
  font-size: 14px !important;
  font-family: ArialMT;
  line-height: 32px;
  width: 210px;
  white-space: nowrap
}
.font_blue {
  color: #2262ff;
}
/deep/.el-button {
  // padding: 0 12px !important;
  font-size: 14px;
  width: 100px;
  font-family: ArialMT;
  ::v-deep .el-icon-search {
    font-size: 14px;
  }
}
::v-deep .el-button--primary {
  color: #fff;
  background-color: #2262ff;
  border-color: #2262ff;
}
::v-deep .el-button--text {
  color: #2262ff;
  margin-left: 0;
}

::v-deep .el-radio-group {
  .el-radio__label {
    color: #242526;
  }
  .is-checked {
    .el-radio__inner {
      background-color: #2262ff;
    }
    .el-radio__label {
      color: #242526;
    }
  }
}

::v-deep .el-table {
  .cell {
    white-space: pre-wrap;
    word-break: keep-all;
  }
}

::v-deep .el-pager {
  li {
    border: 0;
  }
}
.panel-body {
  padding: 0 16px;
}
</style>
