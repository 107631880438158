import fetch from 'common/fetch'

//get请求
export function getBannerList(params) {
  return fetch({
    url: '/enterprise/list',
    method: 'post',
    data: params
  })
}

//get请求
export function getEnterpriseName(params) {
  return fetch({
    url: '/enterprise/getEnterprise',
    method: 'get',
    params
  })
}
// 获取企业详情信息
export function getEnterpriseInfo(params) {
  return fetch({
    url: '/enterprise/'+ params,
    method: 'get',
  });
}
// 地址接口
export function getAddress(params) {
  return fetch({
    url: '/address/childList',
    method: 'get',
    params
  })
}
// restful风格接口参数拼接
export function deleteBanner(id, params) {
  return fetch({
    url: `接口地址/${id}`,
    method: 'delete',
    params
  })
}

//post请求
export function addBusiness(params) {
  return fetch({
    url: '/enterprise',
    method: 'post',
    data : params
  })
}
// 审核接口
export function examineBusiness(params) {
  return fetch({
    url: '/enterprise/audit',
    method: 'post',
    data : params
  })
}
// put
export function modifyBusiness(params) {
  return fetch({
    url: '/enterprise',
    method: 'put',
    data : params
  })
}