import fetch from 'common/fetch';

//get请求 获取表数据
export function getFirstUserList(params) {
  return fetch({
    url: '/system/user/list',
    method: 'post',
    data: params
  });
}

//post 新增表数据
export function addUser(params, res, eid) {
  return fetch({
    // url: '/api/system/user/save?userAgent=' + res + '&eid=' + eid,
    url: '/system/user/save',
    method: 'post',
    data: params
  });
}

//get 获取表数据particulars
export function getParticulars(params) {
  return fetch({
    url: '/system/user/' + params,
    method: 'get'
  });
}

//post 修改用户密码
export function changePassword(params) {
  return fetch({
    url: '/system/user/resetPassword',
    method: 'POST',
    data: params
  });
}
// 日志列表
export function userTaskPage(params) {
  return fetch({
    url: '/system/user/userTaskPage',
    method: 'POST',
    data: params
  });
}

// 下载日志
export function downLoad(params) {
  return fetch({
    url: '/system/user/downloadTarget?fileName=' + params.originFileUrl,
    method: 'get',
    data: params,
    responseType: 'blob'
  });
}

export function getCategoryAndParentVOList() {
  return fetch({
    url: '/system/info/getCategoryAndParentVOList',
    method: 'get'
  });
}
//新增Commodity pools
export function addSKU(params) {
  return fetch({
    url: '/system/pool/saveAll',
    method: 'post',
    data: params
  });
}

//新增Commodity pools
export function addxinhui(params) {
  return fetch({
    url: '/system/record',
    method: 'post',
    data: params
  });
}
// editCommodity pools
export function updateSKU(params) {
  return fetch({
    url: '/system/pool/update',
    method: 'post',
    data: params
  });
}
// 获取Commodity pools底部List
export function getSkuList(params) {
  return fetch({
    url: '/system/pool/info',
    method: 'post',
    data: params
  });
}
// copyCommodity pools
export function putCopy(id, params) {
  return fetch({
    url: '/system/pool/copy/' + id,
    method: 'post',
    data: params
  });
}
// 获取bannerLst
export function getBannerLists(params) {
  return fetch({
    url: '/system/banner/list',
    method: 'post',
    data: params
  });
}
// 新增banner
export function addbanner(params) {
  return fetch({
    url: '/system/banner/add',
    method: 'post',
    data: params
  });
}
// 获取bannerparticulars
export function getdetails(params) {
  return fetch({
    url: '/system/banner/findByid?id=' + params.id,
    method: 'get'
  });
}
// 修改banner
export function updatabanner(params) {
  return fetch({
    url: '/system/banner/update',
    method: 'post',
    data: params
  });
}
// 删除banner
export function deleteBanner(params) {
  return fetch({
    url: 'system/banner/deleteId/' + params.id,
    method: 'delete'
  });
}

// 获取 promotionList
export function getPromotion(params) {
  return fetch({
    url: '/system/brandrecommend/list',
    method: 'post',
    data: params
  });
}
// 新增促销  /system/brandrecommend/add
export function addPromotion(params) {
  return fetch({
    url: '/system/brandrecommend/add',
    method: 'post',
    data: params
  });
}
// 获取促销particulars /system/brandrecommend/findByid?id=?
export function getDetiPromotion(params) {
  return fetch({
    url: '/system/brandrecommend/findByid?id=' + params.id,
    method: 'get'
  });
}
// 修改促销 /system/brandrecommend/update
export function updataPromotion(params) {
  return fetch({
    url: '/system/brandrecommend/update',
    method: 'post',
    data: params
  });
}
// 删除促销
export function deletePromotion(params) {
  return fetch({
    url: '/system/brandrecommend/deleteId/' + params.id,
    method: 'delete',
    data: params
  });
}
// 获取hot commodity列表
export function getSKUList(params) {
  return fetch({
    url: '/system/hotproduct/list',
    method: 'post',
    data: params
  });
}
// 删除hot commodity
export function dleteSKU(params) {
  return fetch({
    url: '/system/hotproduct/deleteContractNo',
    method: 'DELETE',
    data: {
      contractNo: params
    }
  });
}
// 通过合同号获取hot commodity
export function bygetSKU(contractNo, params) {
  return fetch({
    url: '/system/hotproduct/findAllByStuIdAndName',
    method: 'get',
    params
  });
}
// 附表删除
export function fuBygetSKU(params) {
  return fetch({
    url: '/system/hotproduct/deleteSkuIds',
    method: 'delete',
    data: params
  });
}
// 添加hot commodity /insertSkuIdAndContractNo
export function addHotShopping(params) {
  return fetch({
    url: '/system/hotproduct/insertSkuIdAndContractNo',
    method: 'post',
    data: params
  });
}
// 热销进入添加
export function addHotJinShopping(params) {
  return fetch({
    url: '/system/hotproduct/insertSkuIds',
    method: 'post',
    data: params
  });
}
// 下载接口
export function downImg(params) {
  return fetch({
    url: '/system/hotproduct/downloadTarget?fileName=' + params,
    method: 'get',
    responseType: 'blob',
    data: params
  });
}
// brandWall
export function brandList(params) {
  return fetch({
    url: '/system/wall/list',
    method: 'post',
    data: params
  });
}
// brandWallparticulars
export function brandDetail(params) {
  return fetch({
    url: '/system/wall/findByid?id=' + params.id,
    method: 'get',
    params
  });
}
// 新增品牌
export function addBrand(params) {
  return fetch({
    url: '/system/wall/add',
    method: 'post',
    data: params
  });
}
//修改品牌
export function updataBrand(params) {
  return fetch({
    url: '/system/wall/update',
    method: 'post',
    data: params
  });
}
//删除品牌
export function deleteBrand(params) {
  return fetch({
    url: '/system/wall/deleteId/' + params.id,
    method: 'delete',
    params
  });
}
// 获取brandWall表

export function getCenBrand(params) {
  return fetch({
    url: '/system/brandrecommend/getBrand',
    method: 'get',
    params
  });
}
// 获取contract list
export function getContractList(params) {
  return fetch({
    url: '/contractInfo/list',
    method: 'post',
    data: params
  });
}
// 绑定用户
export function userBind(params) {
  return fetch({
    url: '/system/user/bindContract',
    method: 'post',
    data: params
  });
}
// 解绑用户
export function userUnBind(params) {
  return fetch({
    url: '/system/user/unBindContract',
    method: 'post',
    data: params
  });
}
// Create a contract
export function creatContract(params) {
  return fetch({
    url: '/contractInfo/save',
    method: 'post',
    data: params
  });
}
// 获取企业列表
export function getEnterprise(params) {
  return fetch({
    url: '/enterprise/list',
    method: 'post',
    data: params
  });
}
// 获取合同particulars
export function getcontorDetails(params) {
  return fetch({
    url: '/contractInfo/' + params.contractNo,
    method: 'get'
  });
}
// 审核
export function audit(params) {
  return fetch({
    url: '/contractInfo/approve',
    method: 'post',
    data: params
  });
}
// 修改合同
export function putContor(params) {
  return fetch({
    url: '/contractInfo/edit',
    method: 'post',
    data: params
  });
}
// 获取List of discount rates
export function getrecord(params) {
  return fetch({
    url: '/system/record/list',
    method: 'post',
    data: params
  });
}
//导出折扣
export function exportBtn(params) {
  return fetch({
    url: '/system/rate/export',
    method: 'post',
    data: params,
    responseType: 'blob'
  });
}
// 删除折扣
export function deleterecord(params) {
  return fetch({
    url: '/system/record/' + params.id + '?contractNo=' + params.contractNo,
    method: 'delete'
  });
}
// 获取中间列表 3/4
export function getCenList(params) {
  return fetch({
    url: '/system/rate/queryDiscountRates',
    method: 'post',
    data: params
  });
}
// 获取中间列表 1/2
export function getDiscountRatesGroupCate(params) {
  return fetch({
    url: '/system/rate/queryDiscountRatesGroupCate',
    method: 'post',
    data: params
  });
}
// 下载模板
export function downloadTemplat(params) {
  return fetch({
    url: '/system/rate/exportTemplate',
    method: 'get',
    params,
    responseType: 'blob'
  });
}
// 获取SKU上传日志
export function getSKUjournal(params) {
  return fetch({
    url: '/system/record/approvalList',
    method: 'get',
    params
  });
}
// 手动输入sku请求
export function handBtn(params) {
  return fetch({
    url: '/system/record/getSkuIds',
    method: 'post',
    data: {
      skuInfoDTO: {
        skuIdList: params.skuIds.split(',')
      },
    }
  });
}
// 3/4 新增
export function addRecord(params) {
  return fetch({
    url: '/system/record',
    method: 'post',
    data: params
  });
}
// 3/4 编辑
export function upRecord(params) {
  return fetch({
    url: '/system/rate',
    method: 'put',
    data: params
  });
}
// 1/2 新增
export function addByCategory(params) {
  return fetch({
    url: '/system/record/addByCategory',
    method: 'post',
    data: params
  });
}
// 1/2 编辑
export function editByCategory(params) {
  return fetch({
    url: '/system/rate/editByCategory',
    method: 'put',
    data: params
  });
}
// 独立导出
export function oddExport(params) {
  return fetch({
    url: '/system/rate/export',
    method: 'post',
    data: {
      recordId: params.id,
      contractNo: params.contractNo
    },
    responseType: 'blob'
  });
}
//根据合同判断合同号是否有效
export function ifcontor(params) {
  return fetch({
    url: '/contractInfo/flag/' + params,
    method: 'get'
  });
}
// 获取日志
export function getDifcode(params) {
  return fetch({
    url: '/system/record/approvalList',
    method: 'post',
    data: params
  });
}
// 跳转particulars
export function getpush(params) {
  return fetch({
    url: '/system/record/id=' +
      params.id +
      '?contractNo=' +
      params.contractNo,
    method: 'get'
  });
}
export function approvalEdit(id, params) {
  return fetch({
    url: '/system/record/approval?contractNo=' + id,
    method: 'post',
    data: params
  });
}

// 修改状态
export function changeswitch(params) {
  return fetch({
    url: '/contractInfo/modificationContractValidity',
    method: 'get',
    params
  });
}

export function deletefirst(params) {
  return fetch({
    url: '/system/sku/pool/removePoolAndSkuRelation',
    method: 'post',
    data: params
  });
}

export function down(params) {
  return fetch({
    url: `/system/pool/export?skuIds=${params.skuIds}&poolId=${params.poolId}`,
    method: 'get',
    responseType: 'blob'
  });
}

// 下载

export function downLoadText(params) {
  return fetch({
    url: '/system/ware/file/downloadTarget?fileName=' + params,
    method: 'get',
    responseType: 'blob'
  });
}
// 下载模板
export function downTemplat(params) {
  return fetch({
    url: '/system/user/downloadTarget?fileName=' + params,
    method: 'get',
    params,
    responseType: 'blob'
  });
}

// 批量删除
export function BatchDelete(params) {
  return fetch({
    url: '/system/hotproduct/deleteIds',
    method: 'delete',
    data: params
  });
}